@import "Themes/constants.scss";

:root {
	--content-max-width: initial;
	--sub-content-max-width: #{$screen-l};

	--font-primary: "Lato", sans-serif;
	--font-secondary: "Runalto", sans-serif;

	--color-title-1: #000000;
	--color-title-2: #ffffff;
	--color-text: #333333;
	--color-bg-1: #859af3;
	--color-bg-2: #ffffff;
	--color-border: rgba(145, 165, 248, 0.8);


	--background-artworks-1: rgba(170, 167, 253, 0.77);
	--background-artworks-2: rgba(243, 192, 130, 0.77);
	--background-artworks-3: rgba(178, 253, 248, 0.77);

	@media screen and (max-width: $screen-l) {
		--sub-content-max-width: #{$screen-l};
	}

	@media screen and (max-width: $screen-m) {
		--sub-content-max-width: #{$screen-m};
	}

	@media screen and (max-width: $screen-s) {
		--sub-content-max-width: #{$screen-s};
	}

	@media screen and (max-width: $screen-xs) {
		--sub-content-max-width: #{$screen-xs};
	}
}

