@import "Themes/constants.scss";

.root {
	font-family: var(--font-secondary);
	font-style: normal;
	font-weight: 400;
	font-size: 124px;
	line-height: 150px;
	text-align: center;
	letter-spacing: 0.5px;
	width: fit-content;
	margin: auto;
	
	&[color="1"] {
		color: var(--color-title-1);
	}

	&[color="2"] {
		color: var(--color-title-2);
	}

	@media (max-width: $screen-m) {
		font-size: 80px;
		line-height: 96px;
	}

	@media (max-width: $screen-s) {
		font-size: 60px;
		line-height: 72px;
	}
}

.subtitle {
	font-family: var(--font-secondary);
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	text-align: right;
	letter-spacing: 0.5px;
	margin-top: -35px;

	@media (max-width: $screen-m) {
		margin-top: -25px;
	}

	@media (max-width: $screen-s) {
		margin-top: -15px;
	}
}
