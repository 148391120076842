@import "Themes/constants.scss";

.root {
	max-width: var(--content-max-width);
	margin: auto;
	position: relative;
}

.content {
	margin: auto;
}
