.root {
	user-select: none;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 80px;
	background: var(--color-bg-1);
	padding: 0 20px;

	gap: 10px;
    cursor: pointer;
}

.menu-text {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 27px;
	/* or 193% */

	text-align: center;
	letter-spacing: 2.5px;
	text-transform: uppercase;

	color: #ffffff;
	margin: 0;
}
