@import "Themes/constants.scss";

.root {
	margin-top: 90px;

	@media (max-width: $screen-m) {
		margin-top: 90px;
	}

	@media (max-width: $screen-s) {
		margin-top: 110px;
	}

	.background {
		position: absolute;
		top: 0;
		right: 300px;
		height: 382px;
		width: 322px;
		z-index: -1;
		background-image: url("./images/backgrounds/xl.png");
		background-size: contain;

		@media (max-width: $screen-l) {
			width: 83px;
			height: 382px;
			background-image: url("./images/backgrounds/l.png");
			right: 177px;
		}

		@media (max-width: $screen-m) {
			width: 97px;
			height: 382px;
			background-image: url("./images/backgrounds/m.png");
			right: 100px;
		}

		@media (max-width: $screen-s) {
			width: 2px;
			height: 186px;
			background-image: url("./images/backgrounds/s.png");
			right: 80px;
		}
	}

	.team-section-container {
		margin-top: 120px;

		@media (max-width: $screen-m) {
			margin-top: 100px;
		}

		@media (max-width: $screen-s) {
			margin-top: 80px;
		}
	}

	.suwas-container {
		margin-top: 200px;

		@media (max-width: $screen-l) {
			margin-top: 150px;
		}

		@media (max-width: $screen-m) {
			margin-top: 200px;
		}

		> img {
			width: 100%;
		}
	}
}
