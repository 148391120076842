@import "Themes/constants.scss";

.root {
}

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 4px solid;
	border-image-slice: 1;
	border-radius: 30px;
	border-image-source: linear-gradient(270deg, #f5dfde 0%, #f098a9 100%);
	padding-top: 16px;
	padding-bottom: 16px;

	cursor: pointer;
}

.title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: 0.25px;
	color: var(--color-title-1);
	margin-left: 32px;

	@media (max-width: $screen-xs) {
		font-size: 18px;
	}
}

.arrow {
	margin-right: 32px;
	user-select: none;

	transition: transform 300ms ease-in-out;

	&[data-open="true"] {
		transform: rotate(-180deg);
	}
}

.content {
	display: flex;
	align-items: flex-start;

	&[data-animate="true"] {
		transition: background-color 150ms $custom-easing, height 150ms $custom-easing;
	}

	overflow-x: auto;
	overflow-y: hidden;
	background-color: var(--color-9);

	.content-padding {
		margin: 24px;
	}
}

