@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/variables.scss";

iframe{
	pointer-events: none;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-background);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 0;
}

[route="/"] {
	// background-image: url('/assets/images/pages/home/background1.png');
	// background-position: left 0 top 0;
	// background-size: cover;
	// background-repeat: no-repeat;
	
	@media(max-width: $screen-l){
		/*background-image: url('/assets/images/pages/home/background2.png');*/
	}

	@media(max-width: $screen-m){
		/*background-image: url('/assets/images/pages/home/background3.png');*/
	}

	@media(max-width: $screen-s){
		/*background-image: url('/assets/images/pages/home/background4.png');*/
	}
}

[route="/story"] {
	background-image: url('/assets/images/pages/story/background1.png');
	background-position: left 0 top 0;
	background-size: cover;
	background-repeat: no-repeat;

	@media(max-width: $screen-l){
		background-image: url('/assets/images/pages/story/background2.png');
	}

	@media(max-width: $screen-m){
		background-image: url('/assets/images/pages/story/background3.png');
	}

	@media(max-width: $screen-s){
		background-image: url('/assets/images/pages/story/background4.png');
	}
}

[route="/roadmap"] {
	
}


