.root{
    font-family: var(--font-primary);
    font-size: 18px;
    line-height: 27px;
}

.paragraph-title{
    font-weight: 900;
    color: black;
    text-transform: uppercase;
}

.paragraph-subtitle{
    font-weight: 600;
    font-style: italic;
    color: #EF8F37;
}

.paragraph-content{
    font-weight: 500;
    color: black;
    letter-spacing: 2px;

}