@import "Themes/constants.scss";

.root-large-desktop{
    width: 100%;
    display: flex;
    @media(max-width: $screen-l){
        display: none;
    }
}

.root-desktop{
    display: none;
    @media(max-width: $screen-l){
        display: flex;
        width: 100%;
    }

    @media(max-width: $screen-m){
        display: none;
    }
}

.root-tablet{
    display: none;
    @media(max-width: $screen-m){
        display: flex;
        width: 100%;
    }

    @media(max-width: $screen-s){
        display: none;
    }
}

.root-mobile{
    display: none;
    @media(max-width: $screen-s){
        display: flex;
        width: 100%;
    }
}