@import "Themes/constants.scss";

.root {
	font-family: var(--font-secondary);
	font-style: normal;
	font-weight: 400;
	font-size: 52px;
	line-height: 63px;
	letter-spacing: 0.5px;

	color: var(--color-title-1);

	@media (max-width: $screen-m) {
		font-size: 52px;
		line-height: 63px;
	}

	@media (max-width: $screen-s) {
		font-size: 32px;
		line-height: 39px;
	}
}
