@import "Themes/constants.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 2px;
	color: var(--color-title-1);
	margin: 0;
}