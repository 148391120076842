@import "Themes/constants.scss";

.root {
        margin: auto;
		display: flex;
		gap: 40px;
		max-width: 1085px;
		margin-top: 80px;
		position: relative;

		@media (max-width: $screen-l) {
			max-width: 90vw;
		}

		@media (max-width: 1150px) {
			flex-direction: column-reverse;
			align-items: center;
			gap: 60px;
			margin: 40px 110px 0 110px;
		}

		@media (max-width: $screen-s) {
			margin: 20px 24px 0 24px;
		}

		.background-characters {
			position: absolute;
			z-index: -1;
			left: -50px;
			top: 170px;

			@media (max-width: 1150px) {
				left: 0px;
			}
		}

		.artist-left {
			display: flex;
			flex-direction: column;
			.artist-title {
				font-family: var(--font-secondary);
				font-style: normal;
				font-weight: 400;
				font-size: 52px;
				line-height: 63px;
				letter-spacing: 0.5px;

				@media (max-width: $screen-s) {
					font-size: 42px;
					line-height: 51px;
				}
			}

			.artist-subtitle {
				font-family: var(--font-secondary);
				font-style: normal;
				font-weight: 400;
				font-size: 22px;
				line-height: 27px;
				letter-spacing: 0.5px;
				color: #ef8f37;

				display: flex;
				align-items: flex-end;
				gap: 5px;

				@media (max-width: $screen-s) {
					font-size: 16px;
					line-height: 20px;
				}

				&:after {
					content: "";
					display: block;
					height: 8px;
					border-top: 1px solid #ef8f37;
					flex: 1;
				}
			}

			.artist-text {
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 27px;
				/* or 150% */

				letter-spacing: 2px;

				color: #000000;
				margin-top: 20px;
			}

			.artist-foundation {
				cursor: pointer;
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 800;
				font-size: 16px;
				line-height: 27px;
				/* identical to box height, or 169% */

				letter-spacing: 2px;
				text-transform: uppercase;

				color: #686868;

				display: flex;
				gap: 24px;
				align-self: flex-end;
				margin-top: 40px;

				@media(max-width: $screen-s){
					gap: 10px;
					width: 100%;
				}

				@media(max-width: $screen-xs){
					> img {
						width: calc(100% - 230px);
					}
				}
			}
		}

		.artist-right {
            position: relative;
			@media (max-width: $screen-m) {
				width: 100%;
			}

            .small-bar{
                position: absolute;
                z-index: 1;
                left: 60px;
                bottom: -100px;

                @media(max-width: $screen-m){
                    left: unset;
                    right: 43px;
                    bottom: -70px;
                }
            }
			.bleble-picture {
				@media (max-width: $screen-m) {
					width: 100%;
				}
			}
		}
	}