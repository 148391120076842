@import "Themes/constants.scss";

.root {
	margin-top: 90px;

	@media (max-width: $screen-m) {
		margin-top: 90px;
	}

	@media (max-width: $screen-s) {
		margin-top: 110px;
	}
}

.ice-block-container {
	@keyframes float {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-40px);
		}
		100% {
			transform: translatey(0px);
		}
	}

	@keyframes floatresponsive {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}

	width: 65vw;
	margin: auto;
	margin-top: 120px;

	animation: float 6s ease-in-out infinite;

	@media (max-width: $screen-m) {
        margin-top: 30px;
		width: calc(100vw - 48px);
		animation: floatresponsive 6s ease-in-out infinite;
		
	}
}

.ice-block-img{
    width: 100%;
}

.story-content{
	max-width: 65vw;
	margin: auto;
	margin-top: 140px;
	margin-bottom: 240px;
	@media (max-width: $screen-l) {
	}
	
	@media (max-width: $screen-m) {
		margin-top: 32px;
		max-width: calc(100vw - 48px);
		margin-bottom: 200px;
	}
	
	@media (max-width: $screen-s) {
		margin-top: 48px;
		margin-bottom: 60px;
	}
}

.text-container{
	position: relative;
	margin-top: 28px;

	@media (max-width: $screen-l) {
		margin-top: 91px;
	}

	@media (max-width: $screen-m) {
		margin-top: 28px;
	}
	
	@media (max-width: $screen-s) {
		margin-top: 40px;
	}
}

.left-icons{
	position: absolute;
	left: -100px;
	bottom: -215px;
	z-index: -1;

	@media (max-width: $screen-m) {
        left: 0;
	}

	@media(max-width: $screen-s){
		display: none;
	}
}

.right-shape{
	width: 20vw;
	position: absolute;
	right: 0;
	bottom: 0;

	@media(max-width: $screen-m){
		width: 40vw;
	}
	@media(max-width: $screen-s){
		display: none;
	}
}

.summoners-mobile{
	width: 100%;
	@media(min-width: $screen-s){
		display: none;
	}
}