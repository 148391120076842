.burger {
    cursor: pointer;
	path {
        transform: rotate(0deg) translate(0,0);
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
	}

	&[data-state="cross"] {
        /* Burger comportment when menu is opened */
		
	}
}
