@import "Themes/constants.scss";

.root {
	pointer-events: none;
	z-index: 2;
	.nav-separator {
		@media (max-width: $screen-s) {
			display: none;
		}
	}

	.nav {
		position: relative;
		background: var(--color-bg-1);
		font-size: 16px;
		max-height: 100vh;
		transition: transform 400ms $custom-easing, opacity 350ms $custom-easing;
		transform: translateX(-50px);
		opacity: 0;
		width: fit-content;
		padding: 0 40px 0 0;

		display: flex;
		align-items: center;

		@media (max-width: $screen-s) {
			width: unset;
			padding: 0;
			justify-content: center;
			transform: translateY(-100%);
			transition: transform 400ms $custom-easing, opacity 350ms $custom-easing, height 500ms linear;
		}
	}

	&[status="closed"]{
		height: 0px;
	}
	
	&[status="opened"] {
		pointer-events: all;
		.nav {
			transform: translateX(0);
			opacity: 1;
			@media (max-width: $screen-s) {
				transform: translateY(0);
			}
		}
	}

	.side-menu-nav {
		display: flex;
		position: relative;
		align-items: center;
		gap: 56px;
		height: 80px;
		padding: 0 0 0 40px;

		@media (max-width: $screen-s) {
			flex-direction: column;
			height: auto;
			padding: 32px 0;
		}
	}

	.side-menu-nav a {
		text-decoration: none;
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */

		text-align: right;
		letter-spacing: 0.5px;

		color: #ffffff;
		text-transform: uppercase;
	}
}