@import "Themes/constants.scss";

.root {
	width: 100%;
	height: 100%;
	position: relative;
	> img {
		object-fit: cover;
		object-position: top left;
		height: 100%;
		width: 100%;

		@media (max-width: $screen-s) { 
			object-fit: contain;
		 }
	}

	.hover {
		&[data-hovering="false"] {
			opacity: 0;
		}

		&[data-color="1"]{
			background: var(--background-artworks-1);
		}

		&[data-color="2"]{
			background: var(--background-artworks-2);
		}

		&[data-color="3"]{
			background: var(--background-artworks-3);
		}

		opacity: 1;
		transition: opacity 200ms ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;

		@media (max-width: $screen-s) { 
			gap: 5px;
		 }
		.title {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 800;
			font-size: 16px;
			line-height: 27px;
			/* identical to box height, or 169% */

			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;

			color: #ffffff;
			text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.55);

			@media (max-width: $screen-s) { 
				font-size: 12px;
			 }
		}

		.socials {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 27px;
			/* identical to box height, or 150% */

			text-align: center;
			letter-spacing: 2px;

			color: #ffffff;

			text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.55);

			@media (max-width: $screen-s) { 
				font-size: 14px;
			 }
		}
	}
}
