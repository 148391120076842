@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@font-face {
	font-family: "Runalto";
	src: url("./fonts/Runalto.ttf") format("truetype");
	src: url("./fonts/Runalto.otf") format("opentype");
	src: url("./fonts/Runalto.woff") format("woff");
}
