@import "Themes/constants.scss";

.root {
	position: relative;
	bottom: 0;
	background: var(--color-bg-2);
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

	z-index: 10;

	font-family: var(--font-primary);
	font-style: normal;
	font-size: 14px;
	letter-spacing: 0.5px;

	overflow: hidden;
}

.desktop {
	display: flex;
	flex-direction: column;
	@media (max-width: $screen-s) {
		display: none;
	}
}

.content {
	flex-grow: 1;
	display: flex;
	height: 81px;
	align-items: stretch;
	justify-content: center;
}

.segments {
    flex:1;
    display: flex;
	padding: 0 24px;
	max-width: var(--sub-content-max-width);
	border-bottom: 1px solid var(--color-border)
}

.segments-padding {
    flex: 1;
	display: flex;
    flex-direction: row;
	align-items: center;
	padding-right: 160px;
	padding-left: 160px;

	@media (max-width: $screen-m) {
		padding-right: 0;
		padding-left: 0;
	}
}

.segment {
	flex: 1;
	display: flex;
	justify-content: space-between;
	gap: 25px;
}

.segment-left {
	flex-grow: 1;
}

.segment-middle {
	flex-grow: 0;
	margin-left: 130px;
	margin-right: 130px;

	@media (max-width: $screen-m) {
		margin-left: 0;
		margin-right: 0;
	}
}

.segment-right {
	flex-grow: 1;
	justify-content: right;
}

.route {
	font-weight: 600;
	color: var(--color-text);
	text-transform: uppercase;
	text-decoration: none;

	white-space: nowrap;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 15px;

	@media (max-width: $screen-s) {
		padding-bottom: 16px;
	}
}

.logo img {
	width: 44.34px;
}

.copyright {
	height: 49px;
	align-self: center;
	padding-top: 3px;
	font-weight: 400;
	color: var(--color-text);

	@media (max-width: $screen-s) {
		height: 29px;
	}
}

.mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 184px;
	@media (min-width: $screen-s) {
		display: none;
	}
}

.logo-mobile {
	margin-right: 48px;
}

.content-mobile {
	display: flex;
	padding: 32px 32px 21px 32px;
	align-items: flex-start;
}

.routes-mobile {
	display: flex;
	flex-direction: column;
}

.left{
	padding-right: 44px;
}

.border-mobile{
	display: flex;
	justify-content: center;
	align-self: stretch;
	border-bottom: 1px solid var(--color-border);
}

.twitter{
	margin-right: 32px;
}

