@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 250px;

	@media (max-width: $screen-m) {
		margin-top: 140px;
	}

	@media (max-width: $screen-s) {
		margin-top: 45px;
	}
	.subtitle {
		font-family: var(--font-secondary);
		font-style: normal;
		font-weight: 400;
		font-size: 52px;
		line-height: 63px;
		letter-spacing: 0.5px;
		color: #000000;
		@media (max-width: $screen-s) {
			font-size: 42px;
			line-height: 50px;
		}
	}

	.artworks-container {
		margin-top: 40px;
		max-width: 1440px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media (max-width: $screen-m) {
			gap: 10px;
		}
		@media (max-width: $screen-s) {
			display: none;
		}
		.artworks-first-line {
			display: grid;
			grid-template-columns: 36fr 28fr 36fr;
			gap: 26px;

			@media (max-width: $screen-m) {
				gap: 15px;
			}
		}

		.artworks-second-line {
			display: grid;
			grid-template-columns: 28fr 17fr 28.5fr 28fr;
			gap: 18px;

			@media (max-width: $screen-m) {
				gap: 9px;
			}
		}

		.artworks-third-line {
			display: grid;
			grid-template-columns: 36fr 28fr 36fr;
			gap: 26px;

			@media (max-width: $screen-m) {
				gap: 15px;
			}
		}
	}

	.artworks-container-mobile {
		display: none;

		@media (max-width: $screen-s) {
			display: flex;
			flex-direction: column;
		}

		.artworks-one{
		}

		.artworks-two{
			display: grid;
			grid-template-columns: repeat(2,1fr);
		}
	}
}
