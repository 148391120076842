@import "../../Pages/Base/classes.module.scss";

.root {
	margin-left: 12vw;
	margin-right: 12vw;

	@media (max-width: $screen-m) {
		margin-left: 16px;
		margin-right: 16px;
	}

	background-image: url("/assets/images/pages/home/background-faq.png");
	background-position: left 55vw top 570px;
	background-repeat: no-repeat;

	@media (max-width: $screen-xs) {
		background-position: left 24px top 370px;
	}
}

.title {
	display: flex;
	justify-content: center;
}

