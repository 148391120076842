@import "../Base/classes.module.scss";

.root {
	margin-top: -200px;

	@media (max-width: $screen-l) {
		margin-top: 0;
	}

	@media (max-width: $screen-s) {
		background-image: url("/assets/images/pages/home/full-background-mobile.png");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.suwa-girls {
		width: 100%;
	}

	.jumbotron {
		position: relative;
		overflow: hidden;
		video {
			width: 100%;
			@media (max-width: $screen-s) {
				display: none;
			}
		}

		.home-logo {
			width: 420px;
			margin: auto auto 32px auto;

			@media (max-width: $screen-m) {
				width: 50vw;
				margin: auto auto 16px auto;
			}

			@media (max-width: $screen-s) {
				width: 100%;
			}
		}

		.home-text {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			left: 0;
			top: 400px;
			width: 100%;
			margin: auto;

			@media (max-width: $screen-l) {
				top: 120px;
			}

			@media (max-width: $screen-s) {
				top: 110px;
				width: 334px;
				right: 0;
			}

			> p {
				color: var(--color-title-2);
				text-shadow: 0px 2px 9px #00000090;
				text-align: center;
				max-width: 600px;
				margin: auto;
			}
		}
	}

	.bg-home-mobile {
		background-image: none;
		height: 200vw;

		@media (min-width: $screen-s) {
			display: none;
		}
	}

	.text-container {
		margin-top: 28px;
	}
}

.journey-section {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 50px;
	position: relative;
	@media (max-width: $screen-s) {
		background-image: none;
		margin-bottom: 190px;
	}

	.journey-background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
	}

	.journey-background-desktop {
		@media (max-width: $screen-l) {
			display: none;
		}
	}

	.journey-background-mobile{
		display: none;
		@media (max-width: $screen-l) {
			display: block;
		}
	}

	.journey-section-container {
		margin-top: 15vw;
		margin-left: 10vw;
		max-width: 614px;
		text-shadow: 0px 0px 4px white;

		@media (max-width: $screen-l) {
			margin-left: 20vw;
		}

		@media (max-width: $screen-m) {
			max-width: 463px;
			margin-left: 40px;
		}

		@media (max-width: $screen-s) {
			max-width: unset;
			margin-left: 24px;
			margin-right: 24px;
			margin-top: 30vw;
		}

		@media (max-width: $screen-xs) {
			max-width: 342px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 41vw;
		}
	}
}

.eight-summoner-tribes-section {
	overflow: visible;
	position: relative;
	z-index: 1;
	min-height: 111vw;

	background-image: url("/assets/images/pages/home/character_fox_02.png"),
		url("/assets/images/pages/home/bg-8-summoners-summoning-crystal.png");
	background-repeat: no-repeat, no-repeat;
	background-size: 40%, cover;
	background-position: right 0 bottom 0, left 0 top 0;

	@media (max-width: $screen-l) {
		min-height: 187vw;
		background-image: url("/assets/images/pages/home/character_fox_02.png"),
			url("/assets/images/pages/home/bg-8-summoners-summoning-crystal-1440.png");
		background-size: 40%, cover;
		background-position: right -60px bottom 160px, left 0 top 0;
	}

	@media (max-width: $screen-m) {
		min-height: 225vw;
		background-image: url("/assets/images/pages/home/character_fox_02.png"),
			url("/assets/images/pages/home/bg-8-summoners-summoning-crystal-768.png");
		background-size: 50%, cover;
		background-position: right -80px bottom -50px, left 0 top 0;
	}

	@media (max-width: $screen-s) {
		min-height: 320vw;
		background-image: url("/assets/images/pages/home/bg-8-summoners-summoning-crystal-390.png");
		background-size: 100%;
		background-position: left 0 top 0;
	}

	.eight-summoner-tribes-container {
		display: flex;
		justify-content: flex-end;
		
		margin-right: 15vw;

		@media(max-width: $screen-xl){
			margin-top: 5vw;
			margin-right: 4vw;
		}

		@media(max-width: $screen-l){
			margin-top: 15vw;
		}

		@media(max-width: $screen-m){
			margin-top: 300px;
		}
		@media (max-width: $screen-s) {
			justify-content: center;
			margin-right: 0;
			margin-top: 50px;
		}
	}

	.eight-summoner-tribes-content {
		max-width: 550px;
		margin: 0;
		margin-top: 500px;
		text-shadow: 0px 0px 4px white;
		
		@media(max-width: $screen-xl){
			margin-top: 200px;
		}
		@media (max-width: $screen-l) {
			max-width: 400px;
			margin-top: -120px;
		}

		@media (max-width: $screen-m) {
			max-width: 395px;
			margin-top: -270px;
		}

		@media (max-width: $screen-s) {
			max-width: unset;
			margin-top: -150px;
			margin-left: 24px;
			margin-right: 24px;
		}
	}
}

.background {
	width: 100%;
	position: absolute;
	z-index: -1;
}

.roadmap-background {
	width: 50%;
}

.content-container {
	margin-bottom: 70px;
}

.summoning-crystal-section {
	margin-top: 37vw;

	@media (max-width: $screen-l) {
		margin-top: 80vw;
	}

	@media (max-width: $screen-s) {
		margin-top: 130vw;
	}

	.summoning-crystal-container {
		max-width: 80vw;
		margin: auto;

		@media (max-width: $screen-m) {
			display: flex;
			justify-content: center;
		}

		@media (max-width: $screen-s) {
			max-width: unset;
		}
	}

	.summoning-crystal-tribes-content {
		max-width: 585px;
		position: relative;
		z-index: 5;
		@media (max-width: $screen-m) {
			margin-top: 5vw;
			text-align: center;
		}

		@media (max-width: $screen-s) {
			max-width: unset;
			margin: -90px 24px 0px 24px;
		}

		@media(max-width: 420px){
			margin-top: -160px;
		}

		@media(max-width: $screen-xs){
			margin-top: -200px;
		}

		@media(max-width: 321px){
			margin-top: -250px;
		}

		.summoning-crystal-blue-bar{
			position: absolute;
			z-index: -1;
			left: -70px;
			top: 20px;
		}

		.summoning-crystal-blue-text{
			position: absolute;
			z-index: -1;
			top: -100px;
			left: 20px;

			@media(max-width: $screen-s){
				top: 0px;
				height: 360px;
			}

			@media(max-width: $screen-xs){
				top: 200px;
			}
		}
		> p {
			text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.48);
			color: var(--color-title-2)!important;
			z-index: 1;
		}

		> h1 {
			color: var(--color-title-2);
			z-index: 1;
		}
	}
}

.roadmap-section {
	z-index: 1;
	position: relative;
	margin-top: -200px;
	padding-bottom: 400px;
	background-image: url("/assets/images/pages/home/background-roadmap.png");
	background-size: 100%;
	background-position: top 200px center;
	background-repeat: no-repeat;

	@media (max-width: $screen-l) {
		background-image: url("/assets/images/pages/home/background-roadmap-1440.png");
		padding-bottom: 300px;
	}

	@media (max-width: $screen-m) {
		background-image: url("/assets/images/pages/home/background-roadmap-tablet.png");
		background-size: 90%;
		background-position: top 200px center;
		padding-bottom: 150px;
	}
	@media (max-width: $screen-s) {
		margin-top: -100px;
		background-size: 95%;
		background-position: top 400px center;
	}

	.roadmap-container {
		display: flex;
		flex-direction: column;
		font-size: 10px;
		margin-left: 30vw;
		margin-right: 30vw;

		> h1 {
			text-align: center;
		}

		@media (max-width: $screen-m) {
			margin-left: 24px;
			margin-right: 24px;
		}

		.roadmap-left-align {
			margin-right: 5vw;
			@media (max-width: $screen-s) {
				margin: 0;
			}
		}

		.roadmap-right-align {
			margin-left: 5vw;
			@media (max-width: $screen-s) {
				margin: 0;
			}
		}
	}

	.content-title {
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 900;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: #000000;
		margin-top: 0;
		margin-bottom: 2px;
	}

	.content-legend {
		font-family: var(--font-primary);
		font-style: italic;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 2px;
		color: #ef8f37;
		margin-top: 2px;
		margin-bottom: 0;
	}

	.content-text {
		font-family: var(--font-primary);
		font-style: "normal";
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 2px;
		color: #000000;
	}
}

.team-section {
	margin: auto;
	margin-bottom: 141px;
	margin-top: 80px;
	background-image: url("/assets/images/pages/home/background1-team.png");
	background-size: 100%;
	background-position: top;
	background-repeat: no-repeat;

	position: relative;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	align-items: center;
	flex-direction: column;

	max-width: 1050px;

	.team-text {
		@media (max-width: $screen-s) {
			max-width: 340px;
		}

		@media (max-width: $screen-xs) {
			max-width: 95%;
		}
	}

	.team-members {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 80px;
		justify-content: center;
		margin-top: 40px;

		@media (max-width: $screen-s) {
			gap: 35px;
		}
	}

	.button-tribe-container {
		margin-top: 50px;
		.button-tribe {
			text-decoration: none;
			font-family: "Lato";
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 27px;
			/* identical to box height, or 150% */

			text-align: center;
			letter-spacing: 2px;

			/* Black-to-white/00 */

			color: #fefefe;
			background: #6d68f5;
			border-radius: 57px;
			padding: 14px 20px;
		}
	}

	.person-name {
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 27px;
		text-align: center;
		letter-spacing: 2px;
		color: #000000;
		margin-bottom: 0;
	}

	.person-job {
		font-family: var(--font-primary);
		font-style: italic;
		font-weight: 800;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		letter-spacing: 2px;
		color: #8a87f9;
		margin: 0;
	}

	.team-person {
		display: flex;
		flex-direction: column;
		width: 28%;
		@media (max-width: $screen-s) {
			width: 85%;
		}
	}

	.profile-picture {
		@media (max-width: $screen-s) {
			width: 85%;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.top-footer-img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	width: 70%;
}

.previous-work-links {
	padding-top: 16px;
	.previous-work-container {
		display: flex;
		gap: 26px;
		margin-top: 8px;
		.previous-work-left {
			width: 100px;
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 27px;
			/* identical to box height, or 150% */

			letter-spacing: 2px;

			color: #000000;
		}

		.previous-work-right {
			> a {
				text-decoration: none!important;
				color: var(--color-bg-1)!important;
			}
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 27px;
			/* identical to box height, or 150% */

			letter-spacing: 2px;

		}
	}
}
