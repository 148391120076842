@import "Themes/constants.scss";

.root {
	position: fixed;
	top: 0;
	display: flex;
	z-index: 11;
	width: fit-content;

	left: 250px;

	@media (max-width: $screen-l) {
		left: 24px;
	}

	@media (max-width: $screen-s) {
		left: 0;
		flex-direction: column;
	}

	.squares-container {
		display: flex;

		.square-left {
			display: flex;
			align-items: center;
			height: 80px;
			background: white;
			padding: 0 20px;
			z-index: 5;

			.logo img {
				width: 45px;
			}
		}

		.square-right {
			z-index: 5;
		}
	}
}

