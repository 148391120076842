@import "Themes/constants.scss";

.root {
	.clouds-container{
		position: absolute;
		top: 0px;
		z-index: -1;
		width: 100%;
		overflow: hidden;
	}

	.clouds-left{
		width: 50%;
	}

	.clouds-right{
		width: 50%;
	}
	padding-top: 90px;

	@media (max-width: $screen-m) {
		padding-top: 90px;
	}

	@media (max-width: $screen-s) {
		padding-top: 115px;
	}

	.first-line-container {
		margin: auto;
		width: fit-content;
		margin-top: 80px;

		@media (max-width: $screen-m) {
			margin-top: 60px;
		}

		@media (max-width: $screen-s) {
			margin-top: 20px;
		}
	}

	.roadmap-content-container {
		margin-top: 190px;

		@media (max-width: $screen-s) {
			margin-top: 70px;
		}
		.first-part-container {
			position: relative;
			display: flex;
			margin: 0 10vw;
			gap: 150px;

			@media (max-width: $screen-l) {
				margin: 0 0 0 10vw;
				gap: 100px;
			}

			@media (max-width: $screen-m) { 
				gap: 50px;
			 }
			@media (max-width: $screen-s) {
				flex-direction: column;
				gap: 40px;
				margin: 0;
			}

			.first-part-txt {
				width: 60%;
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 100px;

				@media (max-width: $screen-l) {
					width: 50%;
				}

				@media (max-width: $screen-m) { 
					width: 55%
				 }
				@media (max-width: $screen-s) {
					gap: 40px;
					padding: 0 24px;
					width: auto;
				}
			}

			.first-part-girls {
				position: relative;

				@media (max-width: $screen-m) { 
					width: 45%
				 }

				@media (max-width: $screen-s) {
					width: auto;
				}
				> img {
					width: 100%;
					@media (max-width: $screen-s) {
						width: 80%;
						float: right;
					}
				}
			}
		}

		.second-part-container {
			margin: 100px 10vw 200px 10vw;
			display: flex;
			gap: 150px;
			align-items: center;
			@media (max-width: $screen-l) {
				margin: 100px 10vw 200px 0;
				gap: 100px;
			}
			@media (max-width: $screen-s) {
				gap: 20px;
				margin: 0;
				align-items: flex-start;
				flex-direction: column-reverse;
			}

			.second-part-portal {
				width: 35%;

				@media (max-width: $screen-l) {
					width: 45%;
				}
				@media (max-width: $screen-s) {
					width: 80%;
				}
				.left-portal {
					width: 100%;
					@media (max-width: $screen-l) {
						display: none;
					}
				}

				.left-portal-mobile {
					display: none;
					@media (max-width: $screen-l) {
						display: inline-block;
						width: 100%;
					}
				}
			}
			.second-part-txt {
				width: 65%;
				position: relative;
				margin-top: 150px;
				display: flex;
				flex-direction: column;
				gap: 100px;

				@media (max-width: $screen-l) {
					width: 55%;
				}

				@media (max-width: $screen-s) {
					gap: 40px;
					margin-top: 40px;
					padding: 0 24px;
					width: auto;
				}

				.left-padding-container {
					padding-left: 100px;

					@media (max-width: $screen-s) {
						padding: 0;
					}
				}
			}
		}
	}
}

.characters-background {
	position: absolute;
	top: 120px;
	left: 40px;
	z-index: -1;

	@media (max-width: $screen-s) {
		display: none;
	}
}

.first-shape {
	position: absolute;
	z-index: -1;
	right: -150px;
	top: 250px;

	@media (max-width: $screen-s) {
		right: 50px;
		top: 80px;
	}
}

.second-shape {
	position: absolute;
	z-index: -1;
	top: 120px;
	left: 120px;

	@media (max-width: $screen-s) {
		left: 40px;
		top: 80px;
	}
}
